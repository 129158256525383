import React, { useEffect, useContext, useState } from 'react';
import collectionCard1 from '../../assets/images/collection4.jpg';
import AuthContext from '../../context/authContext';
import {PublicLayaltyCardData,MyVoucherData } from '../../types/CustomType';

import { USER_INFO_KEY, YJ_ACCESS_TOKEN,IS_USER_LOGIN } from '../../configuration';
import { useNavigate } from 'react-router-dom';

import '../style/collectPointCardsDetailStyle.css'
import { UserInfoContext } from '../../context/userInfoContext';
import { useParams } from 'react-router-dom';
import { ORIGINAL_URL } from '../../configuration';
import { sendQRcode } from '../../apis/LoyaltyCardService';
import { sendCoupon } from '../../apis/CouponService';

import { Image, Button, Divider,Modal } from 'antd';

type RouteParams = {
    id: string | undefined;
    qrcode: string | undefined;
};

const CollectPointCardsDetail = () => {


    const navigate = useNavigate();
    const {id,qrcode} = useParams<RouteParams>();

    const authCtx = useContext(AuthContext);
    const userContext = useContext(UserInfoContext);


    const [index, setIndex] = useState(-1);

    // Modal
    const [openModal, setOpenModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [confirmLoading, setConfirmLoading] = useState(false);


    // invalid Modal
    const [openInvalidModal, setInvalidOpenModal] = useState(false);
    
    

    
    const detectQRCodeEvent = async (uuid:string,value: any)  => {
        // api call 法以後再優化
        try {
            const updatePayload = {
                "answer": value, //QRCode編號
                "loyalty_card_uuid": uuid,
            };

            await sendQRcode(updatePayload)
            .then((response) => {
                const data = response?.data.message;
                //console.log("[qrcode] success response: ", data);
                setModalText(data);
                showModal();
            })
            .catch((error) => {
                //console.log("[qrcode] error response: ", error);
                // const data = error?.data.response.data;
                

                if (error.response) {
                    
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);

                    const jsonData = JSON.parse(error.response.data);

                    setModalText(jsonData.message);
                    showModal();
                } else if (error.request) {
                    
                    //console.log(error.request);
                    setModalText("目前伺服器維護中，請稍等");
                    showModal();
                } else {
                    
                    //console.log('Error', error.message);
                    setModalText("目前伺服器維護中，請稍等");
                    showModal();
                }


            });
            // authCtx.setUpdateInfo(false);
            authCtx.updateUserInfo();
           
        }
        catch (error) {
            //console.log('post qr code error:', error);
        }
    };


    // 兌換 API 狀態
    const [loading, setLoading] = useState(false)

    // 處理 兌換 API
    async function handleLogin(values: any) {
      setLoading(true)
      try {
        const updatePayload = {
            "voucher_uuid": values, //QRCode編號
        };
        await sendCoupon(updatePayload)
            .then((response) => {
                setLoading(false)
                const data = response?.data.message;
                //console.log("[兌換卷] success response: ", data);
                setModalText('恭喜兌換成功');
                showModal();
            })
        .catch((error) => {
            //console.log("[兌換卷] error response: ", error);
            setLoading(false)
            const data = error?.message;
            setModalText('提示! 兌換失敗');
            showModal();
        });
      } finally {
        setLoading(false)
      }
    }

    // 看行程
    const showItierary = (uuid:string,itinerary_name:string) => {
        navigate("/mobile/itinerary/"+uuid+"/"+itinerary_name);
    };

    // Modal
    const showModal = () => {
        setOpenModal(true);
    };

    const handleOk = () => {
        // setModalText('恭喜!搜集到了一個點數!');
        setConfirmLoading(true);
        authCtx.updateUserInfo();
        // TODO remove setTimeout and post code to server.
        setTimeout(() => {
            setOpenModal(false);
            setConfirmLoading(false);
        }, 1500);
    };


    const handleInvalidOk = () => {
        
        setInvalidOpenModal(false);
        window.location.replace("/mobile");
    };



    useEffect(() => {

        // console.log("[CollectPointCardDetail] 進入點: "+id);
        // console.log("[CollectPointCardDetail] authCtx.isUpdateInfo: "+authCtx.isUpdateInfo);
        // console.log("[CollectPointCardDetail] qrcode: "+qrcode);
        // console.log("[CollectPointCardDetail] id: "+id);

        localStorage.setItem(ORIGINAL_URL, location.pathname);

        if(id != undefined && authCtx.isMobileLogin && authCtx.isUpdateInfo ){
            // authCtx.setUpdateInfo(false);
            // authCtx.updateUserInfo();
            // userContext.setDisplayCollectPointCardDetail(true);
            
            // console.log("[CollectPointCardDetail] 進入點: 2");
            if(qrcode!= undefined && qrcode!= null && qrcode!= "default"){

                const index = authCtx.userInfo?.publicLayaltyCards.findIndex((card) => card.uuid === id);
                // authCtx.userInfo?.publicLayaltyCards?.forEach((card) => {
                //     console.log("UUID:", card.uuid);
                // });
                // console.log("我要看 uuid : "+authCtx.userInfo?.publicLayaltyCards[index]?.detail_info.length);
                
                setIndex(index)
                //detectQRCodeEvent(authCtx.userInfo?.publicLayaltyCards[index].uuid,qrcode);
                if(index >= 0)
                    detectQRCodeEvent(authCtx.userInfo?.publicLayaltyCards[index].uuid,qrcode);
                else 
               
                    setInvalidOpenModal(true);
                 

            } 
            else if(qrcode!= undefined && qrcode!= null && qrcode== "default") {
                const index = authCtx.userInfo?.publicLayaltyCards.findIndex((card) => card.uuid === id);
               
                setIndex(index)
               
            }
           
        } else {
            // localStorage.setItem(ORIGINAL_URL, location.pathname)
            authCtx.setUpdateInfo(false);
            authCtx.updateUserInfo();
        }

        
    }, [id,authCtx.isUpdateInfo]);

    


    const getStatus = (id:string) => {

        // console.log("兌換卷狀態:"+
        //     authCtx.userInfo?.defaultVouchers[index]?.status
        //     +" "
        //     +(authCtx.userInfo?.defaultVouchers[index]?.reward_limit - authCtx.userInfo?.defaultVouchers[index]?.current_reward_count)
        //     +" "
        //     +(authCtx.userInfo?.publicLayaltyCards[index]?.is_done)
        // );

        if(authCtx.userInfo?.defaultVouchers[index]?.status == "未兌換" && authCtx.userInfo?.publicLayaltyCards[index]?.is_done) {
            return false;
        }
        else {

            return true;
            
        }
    };

    return (        
        <>
            <Modal
                title="系統訊息"
                open={openModal}
                onOk={handleOk}
                confirmLoading={confirmLoading}  
                onCancel={handleOk}   
                footer={[
                    <Button 
                        key="submit" 
                        onClick={handleOk} 
                        className=' bg-primary text-white'
                        loading={confirmLoading}
                    >
                        確認
                    </Button>
                ]}
                // onCancel={handleCancel}
            >
                <p>{modalText}</p>
            </Modal>
            
            <Modal
                title="系統訊息"
                open={openInvalidModal}
                onOk={handleInvalidOk}
                onCancel={handleInvalidOk}   
                footer={[
                    <Button 
                        key="submit" 
                        onClick={handleInvalidOk} 
                        className=' bg-primary text-white'
                        
                    >
                        確認
                    </Button>
                ]}
                >
                <p>{"無效的 QR Code 指令"}</p>
            </Modal>

            { 
                (authCtx.isMobileLogin && id != undefined && authCtx.isUpdateInfo && index >= 0) 
                &&
                <div className='h-full w-full  flex-col '>
                    <div className='flex w-full h-40 sm:h-60 bg-blue-600'>
                        <img
                            className='object-cover h-40 sm:h-60 w-full '
                            src={authCtx.userInfo?.defaultVouchers[index]?.voucher_image}
                        />
                    </div>
                    <div className='flex h-40 sm:h-60  w-full shadow-md rounded-lg relative'>
                        <img
                            className='object-cover h-40 sm:h-60 w-full '
                            src={authCtx.userInfo?.publicLayaltyCards[index]?.loyalty_card_image}
                        />
                        <div className='flex absolute justify-left items-left pr-20 py-10 h-full '>
                            {
                                authCtx.userInfo?.publicLayaltyCards[index]?.detail_info.length > 4 ?
                                <div className=' grid gap-2  grid-cols-3 w-56 pl-10 justify-items-left'>
                                    {
                                        authCtx.userInfo?.publicLayaltyCards[index]?.detail_info.map((value, index_) => (
                                            <div key={index_} className="h-7 w-7 my-2 sm:h-12 sm:w-12 sm:mx-6">
                                            <img 
                                                key={'img'+index_} 
                                                className="object-fill h-full w-full" 
                                                src={value ? authCtx.userInfo?.publicLayaltyCards[index].stamp_icon : authCtx.userInfo?.publicLayaltyCards[index].un_stamp_icon}
                                            />
                                        </div>   
                                        ))
                                    }
                                </div>
                                : 
                                <div className=' grid gap-2  grid-cols-2 w-56 pl-10 justify-items-left'>
                                    {
                                        authCtx.userInfo?.publicLayaltyCards[index]?.detail_info.map((value, index_) => (
                                            <div key={index_} className="h-7 w-7 my-2 sm:h-12 sm:w-12 sm:mx-6">
                                            <img 
                                                key={'img'+index_} 
                                                className="object-fill h-full w-full" 
                                                src={value ? authCtx.userInfo?.publicLayaltyCards[index].stamp_icon : authCtx.userInfo?.publicLayaltyCards[index].un_stamp_icon}
                                            />
                                        </div>   
                                        ))
                                    }
                                </div>
                            }
                            
                        </div>
                    </div>
                    <div className=' bg-white text-left p-2 rounded-lg shadow-md'>
                        <div className='py-1'>
                            <Button className='text-white font-semibold bg-primary w-full mt-auto'   onClick={() => showItierary(authCtx.userInfo?.defaultVouchers[index]?.itinerary_uuid,authCtx.userInfo?.defaultVouchers[index]?.itinerary_name)}> 查看活動路線 </Button> 
                        </div>  
                        <div className='py-1'>
                            <div className=' font-extrabold'>
                                兌換時間:
                            </div>
                            <div className='pl-4'>
                                {/* {`${authCtx.userInfo?.defaultVouchers[index]?.start_time}~${authCtx.userInfo?.defaultVouchers[index]?.exp_time} 止`} */}
                                {`${authCtx.userInfo?.defaultVouchers[index]?.description}`}
                            </div>
                        </div>
                        <div className='py-1'>
                            <div className=' font-extrabold'>
                                兌換地點:
                            </div>
                            <div className='pl-4'>
                                {authCtx.userInfo?.defaultVouchers[index]?.address}
                                {/* 「兌換禮限量 <b>{authCtx.userInfo?.defaultVouchers[index]?.reward_limit}</b> 份，目前已兌現 <b>{authCtx.userInfo?.defaultVouchers[index]?.current_reward_count}</b> 份，換完為止。」 */}
                            </div>
                        </div>
                        <div className='py-1'>
                            <div className='font-bold'>
                                <div>
                                    使用方式和注意事項:
                                    {authCtx.userInfo?.defaultVouchers[index]?.warning.split("\n").map((context, index) => (
                                        <li key={index} className="liNotBold">{context}</li>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='py-1'>

                        {
                            
                                            
                            authCtx.userInfo?.defaultVouchers[index]?.reward_limit - authCtx.userInfo?.defaultVouchers[index]?.current_reward_count>0  ?

                            <Button className='text-white font-semibold bg-primary w-full mt-auto' disabled={getStatus(id)} loading={loading} onClick={() => handleLogin(authCtx.userInfo?.defaultVouchers[index]?.uuid)}>  
                                確認兌換
                            </Button> 
                            :
                            <Button className='text-white font-semibold bg-primary w-full mt-auto' disabled={true} loading={loading} onClick={() => handleLogin(authCtx.userInfo?.defaultVouchers[index]?.uuid)}>  
                                確認兌換 (兌換禮已全數兌完畢)
                            </Button> 
                        }    
                        
                            
                        </div>        
                    </div>
                </div>
               
            }      
        </>
    )
}


export default CollectPointCardsDetail;