import { post, get, getAuthHeader } from './base';
import { API_URL } from '../configuration';
import { useQuery } from 'react-query';
import {AttractionData} from '../types/CustomType';
import { StringDecoder } from 'string_decoder';

import { LoyaltyCardDetectQCodeData } from '../types/CustomType';


// 掃描 QR code，對應集章卡的 API
const getQRcode  = async (uuid : string): Promise<LoyaltyCardDetectQCodeData> => {
    const response = await get(`${API_URL}/api/loyaltyCard?layaltycard_uuid=${uuid}`);
    return await response.data.message;
};

export const sendQRcode = async (payload: object) => {
    return await post(`${API_URL}/api/myloyaltyCard`, payload, getAuthHeader())
}



