import React, { useEffect, useState, useContext, useRef } from 'react';
import { Modal, Button } from 'antd';
import './style/qrcodeScannerStyle.css';
import { sendQRcode } from '../apis/LoyaltyCardService';
import AuthContext from '../context/authContext';
import { useNavigate } from 'react-router-dom';
import { BrowserQRCodeReader, BrowserMultiFormatReader } from '@zxing/library';

interface CustomModalProps {
  isVisible: boolean;
  title: string;
  onClose: () => void;
  children: React.ReactNode;
}

const QRScannerV2: React.FC<CustomModalProps> = ({
  isVisible,
  title,
  onClose,
  children,
}) => {
  const [codeReader, setReader] = useState<BrowserMultiFormatReader | null>(null);
  const [scanning, setScanning] = useState(false);
  const [tip, setTip] = useState('');
  const processing = useRef(false);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isVisible && !scanning) {
      startScanning();
    }
  }, [isVisible]);

  useEffect(() => {
    if (codeReader && isVisible) {
      codeReader.getVideoInputDevices().then((videoInputDevices: any[]) => {
        const backCamera = videoInputDevices.find((device: { label: string }) =>
          device.label.toLocaleLowerCase().includes('back')
        );
        const frontCamera =
          videoInputDevices.find((device: { label: string }) =>
            !device.label.toLocaleLowerCase().includes('back')
          ) || videoInputDevices[0];

        if (backCamera) {
          const deviceId = backCamera.deviceId;
          startVideoStream(deviceId);
        } else if (frontCamera) {
          const deviceId = frontCamera.deviceId;
          startVideoStream(deviceId);
        }
      }).catch((error: any) => {
        console.error(error);
        stopScanning();
      });
    }
  }, [codeReader]);

  const startScanning = () => {
    if (!scanning) {
      const reader = new BrowserMultiFormatReader();
      setReader(reader);
      setScanning(true);
    }
  };

  const startVideoStream = (deviceId: string) => {
    const videoElement = document.getElementById('video') as HTMLVideoElement;
    if (!videoElement) return;

    // Stop the previous video stream if it's running
    const stream = videoElement.srcObject as MediaStream;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }

    // Start the new video stream
    codeReader?.decodeFromInputVideoDeviceContinuously(deviceId, 'video', (result: any, error: any) => {
      if (result) {
        processing.current = true;
        detectQRCodeEvent(result.text);
      }
      if (error) {
        console.error(error);
      }
    });
  };

  const detectQRCodeEvent = async (qr_id: string) => {
    try {
      setTip('已經偵測 QR code，系統處理中...');
      setTimeout(() => {
        onClose();
        setTip('');
        processing.current = false;
        window.location.href = qr_id;
      }, 1500);
    } catch (error) {
      console.log('post qr code error:', error);
    }
  };

  const stopScanning = () => {
    const videoElement = document.getElementById('video') as HTMLVideoElement;
    const stream = videoElement?.srcObject as MediaStream;
    const tracks = stream?.getTracks();

    tracks?.forEach((track) => track.stop());
    setReader(null); // Reset the reader
    setScanning(false);
    onClose();
    navigate('/mobile');
  };

  const customFooter = (
    <>
      <Button style={{ backgroundColor: '#179188', color: '#fff' }} onClick={stopScanning}>
        取消
      </Button>
    </>
  );

  return (
    <Modal
      className="centered-title-modal"
      title={title}
      open={isVisible}
      footer={customFooter}
      closable={false}
      maskClosable={false}
      style={{ top: '50%', transform: 'translateY(-50%)' }}
    >
      <div className="video-container">
        <video id="video" />
      </div>
      <div id="tip" className="tip-container">
        {tip}
      </div>
    </Modal>
  );
};

export default QRScannerV2;
