export const LINE_LOGIN_URI = 'https://access.line.me/oauth2/v2.1/authorize'

export const LINE_LOGIN_CLIENT_ID = '2000147744'
export const LINE_REDIRECT_URL = 'http://localhost:3000/callback'
export const USER_INFO_KEY = 'user-info';
export const IS_USER_LOGIN = 'is-user-login';
export const YJ_ACCESS_TOKEN = 'yj-access-token'; //永靖的token

export const API_URL = 'https://api.512better.org.tw'; //正式用

// export const API_URL = 'http://api.localhost'; //測試用


export const IS_MOBILE_LOGIN = 'is-mobile-login';
export const ORIGINAL_URL = '/mobile';


// [正式版] login in
export const LINE_CHANNEL_ID = '2000457318';
export const LINE_REDIRECT_URI = 'https://mobile.512better.org.tw/mobile/callback';
export const LINE_CHANNEL_SECRET = '13b0be42a99b79d1c04a173fea055d56';

// [測試版] login in
// export const LINE_CHANNEL_ID = '2005197242';
// export const LINE_REDIRECT_URI = 'http://localhost:3000/mobile/callback'; 
// export const LINE_CHANNEL_SECRET = '0700d03b01d0c06fe1ddf87c26df94f7';


export const COOKIE_NAME = 'lineLoginStatus';
export const COOKIE_GET_LINETOKEN_NAME = 'lineGetToken';
export const COOKIE_LOGUT_NAME = 'lineLogout';



// export const  LINE_LOGIN_SECRET=

// export const LINE_CHANNEL_SECRET = 'c969e8c72a34198b028d3929fb03c657'
// export const LINE_LOGIN_CLIENT_ID = '2000037430'


// export const LINE_LOGIN_URI = 'https://access.line.me/oauth2/v2.1/authorize'
// export const LINE_CHANNEL_SECRET = 'be25684436200bfdfa9854e72dfd03a2'
// export const LINE_CHANNEL_ACCESS_TOKEN = 'LHyn8v+WY9kGZTsEySiTht9cqXcZswfalm7Z76AK/W+vA2teUr6aqpDHzCLPL1iZux990ewCTa4csKhHdDhRhlTRUIJ1CI0DKpo2NJgMm6mHiZ71LnUoWUXhxPcfLzBz4xtF9Fn45HVK1fWIwJDP+QdB04t89/1O/w1cDnyilFU='
// export const LINE_LOGIN_CLIENT_ID = '2000029553'